import Slide1 from '../../img/slide1.jpeg'
import Slide2 from '../../img/slide2.jpeg'
import Slide3 from '../../img/slide3.jpeg'

export default [
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide3,
    },
    // {
    //     title: "Second Slide",
    //     subtitle: "denem",
    //     image: Slide2
    // },
    // {
    //     title: "Third Slide",
    //     subtitle: "last one! ✨",
    //     image: Slide3
    // },

]