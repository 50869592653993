import React, { Fragment } from 'react';
import TopV2 from '../../layouts/topV2'
import MailPanel from '../../components/mailPanel'
import Bottom from '../../layouts/bottom'
function ContactUs() {

    return <Fragment>
        <TopV2 name='İletişim'/>
        <MailPanel />
        <Bottom />
    </Fragment>
}


export default {
    routeProps: {
        path: '/contact-us',
        component: ContactUs
    },
    name: 'İletişim',
    headerLocale: true
    //icon:<IoMdAnalytics />
}