import React from 'react';
import styled from 'styled-components'

const Waver = styled.div`
    width: 100%;
    padding: 20px 120px;
    box-sizing:border-box;
    float:left;
    @media only screen and (max-width:1000px) {
        padding: 20px 10px;
    }
`;
const HR = styled.hr`
    border-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 15" width="30" height="18"><path fill="none" stroke="rgba(255,180,1, 0.9)" stroke-width="4" d="M0,13.5c12.5,0,12.5-12,25-12s12.5,12,25,12"/></svg>') 0 0 100% repeat;
    border-width: 0 0 25px;
`



export default function Separator() {
    return <Waver><HR bgcolor="#FFB401"></HR></Waver>
}