import React, { useState, Fragment } from 'react';
import styled from 'styled-components'
import Modal from './modal'
import PlayButton from './playButton'
import Video_1 from '../../img/video/Video_1.mp4'
import Video_2 from '../../img/video/Video_2.mp4'
import Video_3 from '../../img/video/Video_3.mp4'
import Video_4 from '../../img/video/Video_4.mp4'
import Video_5 from '../../img/video/Video_5.mp4'
import Video_6 from '../../img/video/Video_6.mp4'
import Video_7 from '../../img/video/Video_7.mp4'
import Video_8 from '../../img/video/Video_8.mp4'
import Video_9 from '../../img/video/Video_9.mp4'
import Video_10 from '../../img/video/Video_10.mp4'
const VideoWrapper = styled.section`
 width:100%;
 float:left;
 padding:40px 120px 80px 120px;
 box-sizing:border-box;
 display:flex;
 flex-direction:row;
 flex-wrap: nowrap;
 justify-content:flex-start;
 /* align-content:stretch; */
 align-items:flex-start;
 align-content:flex-start;
 height:80vh;
 @media only screen and (max-width:1000px) {
    padding:40px 10px 80px 10px;
    flex-wrap: wrap;
    height:auto;
 }
`;

const BigVideo = styled.section`
    display:flex;
    flex-wrap: wrap;
    align-items:flex-start;
    align-content:space-between;
    justify-content:space-between;
    flex-direction:row;
    flex: 0 50%;
    margin: 1%;
    height:100%;
    position: relative;
    @media only screen and (max-width:1000px) {
        flex: 0 100%;
 }
    video{
        border-radius:10px;
        width:100%;
        height:100%;
        object-fit: cover;
    }
`;
const MiniVideo = styled.section`
    flex: 0 48%;
    position: relative;
    /* background:blue; */
    height:48%;
    video{
            width:100%;
            height:100%;
            object-fit: cover;
        }
`;
const Baslik = styled.h1`
    text-align:center;
    margin:0px;
`
export default function VideoPanel() {

    const [show, setShow] = useState(false);
    const [videoId, setVideoId] = useState("");

    function showModal(value){
        setShow(true);
        setVideoId(value);
    };

    function hideModal() {
        setShow(false);
    };
    return <Fragment>
        <Baslik>Video Galeri</Baslik>
        <VideoWrapper>
        <BigVideo onClick={() => showModal(Video_5)}>
            <video src={Video_5} />
            <PlayButton></PlayButton>
        </BigVideo>
        <BigVideo>
            <MiniVideo onClick={() => showModal(Video_8)}><video src={Video_8} /><PlayButton/></MiniVideo>
            <MiniVideo onClick={() => showModal(Video_9)}><video src={Video_9} /><PlayButton/></MiniVideo>
            <MiniVideo onClick={() => showModal(Video_10)}><video src={Video_10} /><PlayButton/></MiniVideo>
            <MiniVideo onClick={() => showModal(Video_7)}><video src={Video_7} /><PlayButton/></MiniVideo>
        </BigVideo>
        {show ? <Modal handleClose={() => setShow(false)}><video src={videoId} controls autoPlay/></Modal> : false}
    </VideoWrapper>
    </Fragment> 
    
}