import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components'
import { FaChevronCircleDown } from 'react-icons/fa';

import defaultSlides from './defaultSlides';
const SliderSection = styled.section`
 position:relative;
 height:100vh;
`;
const Imager = styled.img`
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  object-position: 0px -50px;
  @media only screen and (max-width:1000px) {
    object-position: 0px 0px;
    }
`;
const rotate = keyframes`
    0%   {transform:translate(0px,10px);}
    50%  {transform:translate(0px,0px);}
    100% {transform:translate(0px,10px);}
`;
const Content = styled.div`
    width:100%;
    padding:0px 120px;
    float:left;
    position: relative;
    bottom: -150px;
    @media only screen and (max-width:1000px) {
        padding:0px 10px;
    }
    h4{
        color: #fff;
        text-shadow: 2px 2px 10px #000;
        font-size:1.3em;
    }
    h1{
        color: #fff;
        width:50%;
        font-size:1.5em;
        text-shadow: 2px 2px 10px #000;
        @media only screen and (max-width:1000px) {
            width:100%;
    }
    }
    button{
        padding:30px 40px;
        background-color: #FFF267;
        -webkit-border-radius: 63px 63px 63px 63px/
        108px 108px 72px 72px;
        border-radius: 60% 35% 60% 44%/60% 60% 50% 40%;
        box-shadow: 6px 6px #123F61;
        border-style:none;
        color:#123F61;
        font-weight:700;
        font-size:1.3em;
    }
    svg{
        position:absolute;
        bottom:20px;
        left:50%;
        width:35px;
        height:35px;
        color:white;
        animation: ${rotate} 2s linear infinite;
    }
`;


const Slide = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${props => props.i === props.curr ? 1 : 0};
    transition-duration: ${props => props.i === props.curr ? ".2s" : ".3s"};
    transition-timing-function:ease;
    z-index:0;
`;
const Slider = ({ slides }) => {
    // Create a curr state indicating what's the current slide's index
    const [curr, setCurr] = React.useState(0);
    const { length } = slides;

    const goToNext = () => {
        // Check if we've reached the final slide in the array
        // If so, go back to 0, else curr + 1
        setCurr(curr === length - 1 ? 0 : curr + 1);
    }

    // useEffect will run at every re-render
    React.useEffect(() => {
        // At every render, set a new timeout to go to the next slide
        //setTimeout(goToNext, 4000);
        // And, when unmounting <Slider />, clear the timeout
        // See the reactjs.org docs on hooks for more info
        return function () {
            clearTimeout(goToNext);
        }
    })

    if (!Array.isArray(slides) || length <= 0) {
        return null;
    }

    return (
        <SliderSection>
            {slides.map((s, i) => (
                <Slide i curr key={s.title}>
                    {i === curr && (
                        <Fragment>
                            <Content>
                                <h1>{s.title}</h1>
                                <h4>{s.subtitle}</h4>
                                {/* <button>BİLGİ EDİN</button> */}
                                <FaChevronCircleDown />
                            </Content>
                            <Imager src={s.image} alt={`Image for ${s.title}`} />
                        </Fragment>
                    )}
                </Slide>
            ))}
        </SliderSection>
    );
}

export default function Main() {
    return (
        <main>
            <Slider slides={defaultSlides} />
        </main>
    );
}
