import React from 'react';
import styled from 'styled-components'

const PlayButton = styled.div`
width:80px;
height:80px;
background:black;
position:absolute;
left:calc( 50% - 40px );
top:calc( 50% - 40px );
border-radius:50%;
transform: scale(1);
animation: pulse-black 2s infinite;
@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
`
const Triangle = styled.div`
left:calc( 50% - 10px );
top:calc( 50% - 10px );
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 20px solid white;
    border-bottom: 10px solid transparent;
    position: relative;
`

export default function Play(){
    return <PlayButton><Triangle/></PlayButton>
}