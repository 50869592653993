import React, { useState } from 'react';
import styled from 'styled-components';
import Karen from '../../img/karenresim.jpeg'
import AKKA from '../../img/references/AKKA_ALINDA.svg'
import AKKAANTEDON from '../../img/references/AKKA_ANTEDON.svg'
import KEMER from '../../img/references/holiday-club-kemer-hotel-logo.png'
import TUI from '../../img/references/tui blue.png'
import COCOS from '../../img/references/cocos.webp'
import ELA from '../../img/references/elaresort.svg'
import MEGASARAY from '../../img/references/megasaray.webp'
import MEGASARAYMOUNT from '../../img/references/megasaray-mount.png'
const FounderWrapper = styled.section`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    padding:80px 0px;
    box-sizing:border-box;
    
    @media only screen and (max-width:1000px) {
        padding:40px 0px;
    }
   
`;
const ParentGrid = styled.div`
box-sizing:border-box;
    width:900px;
    height:900px;
    display: grid;
    grid-template-columns: 300px 300px 300px;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-ailgn:center;
    @media only screen and (max-width:1000px) {
       display:flex;
       flex-direction:column;
       width:100%;
       flex-wrap:wrap;
       height:auto;
        div{
            display:flex;
            flex-direction:column;
            width:100%;
            height:200px;
            padding:40px;
            box-sizing:border-box;
            img{
                width:100% !important;
                height:100% !important;
                object-fit:contain !important;
            }
        }
    }
    div{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        img{
            width:200px;
        }
        span{
            color:white;
            font-weight:600;
            margin-top:10px;
        }
    }
`;
const FounderRight = styled.div`
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    align-content:center;
    justify-content:center;
    ul{
        display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    list-style:none;
    justify-content:center;
    li{
        padding:20px;
    }
    }
    @media only screen and (max-width:1000px) {
        width:100%;
    }
    button{
        border-radius:40px;
        border-style:none;
        color:white;
        font-weight:bold;
        background-color:#FFB401;
        padding:20px 30px;
        transition:300ms;
        outline:none;
        border:2px solid #FFB401;
        &:hover{
            transition:300ms;
            background:white;
            color:#FFB401;
            border:2px solid #FFB401;
        }
    }
    h1,h4{
        text-align:center;
        @media only screen and (max-width:1000px) {
        text-align:center;
    }
}
    p{
        transition:300ms;
        @media only screen and (max-width:1000px) {
        text-align:center;
    }
    }
`

export default function References() {
    const [acil, setAcil] = useState(true);
    function acillir() {
        setAcil(!acil);
    }
    return <FounderWrapper>
        {/* <FounderLeft>
            <FounderImg src={Karen}/>
        </FounderLeft> */}
        <FounderRight>
            {/* <h1>Kurucumuz Karen Ak kimdir?</h1>    
            <p>“Kidsonly by k®” ve “Kids' Computer by k®” markalarının yaratıcısı ve kurucusu Karen Ak doğup büyüdüğü İstanbul’da Alman Lisesi’ni bitirdikten sonra Almanya’ya giderek yüksek öğrenimini tekstil dalında tamamladı. Alman{acil?" ekolünün kazandırdığı disiplin daha sonra iş hayatındaki başarısının temel taşını oluşturdu. Uzun yıllar bir Alman şirketinin İstanbul temsilciliğinde yöneticilik yaptıktan sonra 1993 yılında Antalya’ya yerleşti ve kızının doğumu ile çocuklar konusunda çalışmaya gönül verdi. Futurekids® ile baslayan çalışmalarına paralel, çocuklar için daha ilginç ve yaratıcı konseptler geliştirmek isteği ile kapsamlı bir hizmet alanına yöneldi. Bunun sonucunda kendi markalarını yarattı. ":"..."} </p> */}
            {/* <button onClick={acillir}>DAHA FAZLA GÖSTER</button> */}
            <h1>Hizmet Verdiğimiz Kuruluşlar</h1>
            <ParentGrid>
                <div style={{ backgroundColor: '#5aa625' }}>
                    <img style={{ filter: 'brightness(10)' }} src={AKKA} />
                    {/* AKKA ANTEDON HOTEL */}
                </div>
                <div style={{ backgroundColor: '#64be71' }}>
                    <img style={{ filter: 'brightness(10)' }} src={AKKAANTEDON} />
                </div>
                <div style={{ backgroundColor: '#63c4b8' }}>
                    <img style={{  }} src={KEMER} />
                </div>
                <div style={{ backgroundColor: '#f3b82f' }}>
                    <img style={{ height:'80px', objectFit:'contain' }} src={TUI} />
                    <span>TUI BLUE COLLECTION BELEK HOTEL</span>
                </div>
                <div style={{ backgroundColor: '#e86c2b' }}>
                    <img style={{ height:'80px', objectFit:'contain' }} src={TUI} />
                    <span>TUI BLUE COLLECTION KİRİŞ HOTEL</span>
                </div>
                <div style={{ backgroundColor: '#5aa625' }}>
                    <img style={{ height:'120px', objectFit:'contain' }} src={COCOS} />
                    {/* <span>TUI BLUE COLLECTION KİRİŞ HOTEL</span> */}
                </div>
                <div style={{ backgroundColor: '#64be71' }}>
                    <img style={{  objectFit:'contain' }} src={ELA} />
                    {/* <span>TUI BLUE COLLECTION KİRİŞ HOTEL</span> */}
                </div>
                <div style={{ backgroundColor: '#63c4b8' }}>
                    <img style={{filter: 'brightness(10)' ,  objectFit:'contain' }} src={MEGASARAY} />
                    {/* <span>TUI BLUE COLLECTION KİRİŞ HOTEL</span> */}
                </div>
                <div style={{ backgroundColor: '#f3b82f' }}>
                    <img style={{filter: 'brightness(10)' ,  objectFit:'contain' }} src={MEGASARAYMOUNT} />
                    {/* <span>TUI BLUE COLLECTION KİRİŞ HOTEL</span> */}
                </div>
            </ParentGrid>
    
            <h4><i>"Birlikte çalışma fırsatı bulduğumuz tüm kuruluşlara teşekkür ederiz."</i></h4>
        </FounderRight>
    </FounderWrapper>
}