
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";
import styled from 'styled-components';

import Aksam from '../../../img/news/aksam.jpg'
import Hurriyet from '../../../img/news/hurriyet.jpg'
import Sabah from '../../../img/news/sabah.jpg'
import R1 from '../../../img/news/r1.pdf'
import K1 from '../../../img/news/karen1.png'
import R2 from '../../../img/news/r2.pdf'
import K2 from '../../../img/news/karen2.png'

const Kapsayici = styled.div`
flex-basis:33%;
display:flex;
flex-direction:column;
// flex-shrink:0;
padding:20px;
box-sizing:border-box;
@media (max-width: 1024px) {
    flex-basis: 33%;
    // flex-shrink:1;
}
@media (max-width: 1000px) {
    flex-basis: 50%;
    // flex-shrink:1;
}
img{
    overflow:hidden;
    width:100%;
    border-radius:20px;
    height:200px;
    object-fit:cover;
    object-position:top;
    @media (max-width: 1000px) {
        width: 100%;
        height:200px;
    }
}
span{
    // float:left;
    margin:10px 0px;
    display:block;
    color:white;
    font-size:1em;
    text-align:center;
}`;
export default class Responsive extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Slider {...settings}>
                    <Kapsayici>
                        <a href={Aksam} target="_blank">
                            <img src={Aksam} />
                        </a>
                        <span>Aksam Gazetesi</span>
                    </Kapsayici>
                    <Kapsayici><a href={R1}
                        target="_blank"
                        ><img src={K1} /></a><span>KAREN AK RÖPORTAJ 01</span></Kapsayici>
                  <Kapsayici><a href={R2}
                        target="_blank"
                        ><img src={K2} /></a><span>KAREN AK RÖPORTAJ 02</span></Kapsayici>
                 <Kapsayici><a href={Sabah}
                        target="_blank"
                        ><img src={Sabah} /></a><span>Sabah Gazetesi</span></Kapsayici>
                    <Kapsayici><a href={Hurriyet}
                        target="_blank"
                        ><img src={Hurriyet} /></a><span>Hürriyet Gazetesi</span></Kapsayici>
                </Slider>
            </div>
        );
    }
}