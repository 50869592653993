import React, { Fragment } from 'react';
import TopV2 from '../../layouts/topV2'
import CenterV2 from '../../layouts/centerV2'
import Bottom from '../../layouts/bottom'
function AboutUs(props) {
 console.log(props)
    return <Fragment>
        <TopV2 name='Hakkımızda'/>
        <CenterV2 />
        <Bottom />
    </Fragment>
}


export default {
    routeProps: {
        path: '/about-us',
        component: AboutUs,
    },
    name: 'Hakkımızda',
    headerLocale: true
    //icon:<IoMdAnalytics />
}