import React from 'react';
import styled from 'styled-components'
import Crown from '../../img/crown.png'
import Balloon from '../../img/balloon.png'
import Time from '../../img/time.png'
import Brush from '../../img/brush.png'
import Bell from '../../img/bell.png'
import Family from '../../img/family.png'

const FeaturesWrapper = styled.section`
width:100%;
float:left;
padding:80px 120px;
box-sizing:border-box;
@media (max-width: 1000px) {
    padding: 40px 0px;
  }
`;
const Feature = styled.div`
 width:16.6%;
 height:auto;
 padding:10px;
 float:left;
 box-sizing:border-box;
 @media (max-width: 1000px) {
    width:50%;
  }
  @media (max-width: 500px) {
    width:100%;
  }
 span{
     text-align:center;
    float:left;
    width:100%;
    margin-top:20px;
    &:hover{
        opacity:.8;
    }
 }
`
const ImageWrapper = styled.div`
width:100%;
height:auto;
display:flex;
`
const ImagePlace = styled.div`
padding: 10px;
float:left;
box-sizing:border-box;
margin:0px auto;
border:none;
background:${props => props.bgcolor};
border-radius:100%;
transition:300ms;
&:hover{
    border-radius:20px;
    transition:300ms;
}

img{
     width:40px;
     height:40px;
     float:left;
 }
`

export default function Features() {
    return <FeaturesWrapper>
        <Feature>
            <ImageWrapper>
                <ImagePlace bgcolor={"#EF5D50"}>
                    <img src={Crown}></img>
                </ImagePlace>
            </ImageWrapper>
            <span>Ekibin oluşturulması</span>
        </Feature>
        <Feature>
            <ImageWrapper>
                <ImagePlace bgcolor={"#FFB401"}>
                    <img src={Balloon}></img>
                </ImagePlace>
            </ImageWrapper>
            <span>Yeni aktivitelerin oluşturulması</span>
        </Feature>
        <Feature>
            <ImageWrapper>
                <ImagePlace bgcolor={"#F1E1CA"}>
                    <img src={Time}></img>
                </ImagePlace>
            </ImageWrapper>
            <span>Mini Club genel işleyiş kontrolü</span>
        </Feature>
        <Feature>
            <ImageWrapper>
                <ImagePlace bgcolor={"#1B4D9A"}>
                    <img src={Brush}></img>
                </ImagePlace>
            </ImageWrapper>
            <span>İki haftalık etkinlik takvimi oluşturulması</span>
        </Feature>
        <Feature>
            <ImageWrapper>
                <ImagePlace bgcolor={"#92CEFC"}>
                    <img src={Bell}></img>
                </ImagePlace>
            </ImageWrapper>
            <span>Spesifik uygulama projeleri</span>
        </Feature>
        <Feature>
            <ImageWrapper>
                <ImagePlace bgcolor={"#60A917"}>
                    <img src={Family}></img>
                </ImagePlace>
            </ImageWrapper>
            <span>Çocuk festivalleri</span>
        </Feature>

    </FeaturesWrapper>
}