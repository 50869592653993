import React from 'react';
import styled from 'styled-components'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Carousel from './carousel'
import SLICK from './slick'
const NewsWrapper = styled.section`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
padding: 40px 120px;
box-sizing:border-box;
background-color:#FF9229;
float:left;
overflow:hidden;
h1{
    text-align:center;
    color:white;
}
@media (max-width: 1000px) {
     padding: 40px 0px;
  }
h3{
    color:white;
    /* width:90%; */
    float:left;
}
`;
const IconWrapper = styled.div`
float:right;
margin-top:2em;
svg{
    color:white;
    width:30px;
    height:30px;
    float:left;
}
`
    ;

export default function News(){
    return <NewsWrapper>
        <h1>Basında Biz</h1>
        {/* <Carousel/> */}
        <SLICK/>
    </NewsWrapper>
}