import React, { Fragment } from 'react';
import MiniInfo from "../../components/miniInfo"
import Features from "../../components/features"
import Sperator from "../../components/sperator"
import Gallery from "../../components/gallery"
import News from "../../components/news"
import Founder from "../../components/founder"
import VideoPanel from "../../components/videoPanel"
import References from "../../components/references"
export default function Center() {
    return <Fragment>
        <MiniInfo />
        <Features />
        <Sperator />
        <Gallery />
        <References/>
        <News />
        <Founder />
        <Sperator/>
        <VideoPanel />
    </Fragment>
}