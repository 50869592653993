import React, { Component, useState } from 'react';
import styled from 'styled-components';

import SlidesStore from './defaultSlides'

const Title = styled.h3`
 font-size:30px;
 color:#2D2D2D;
 text-align:center;
`
const GaleriWrapper = styled.section`
width:100%;
float:left;
padding:40px 120px;
box-sizing:border-box;
@media only screen and (max-width:1000px) {
    padding:40px 10px;
    }
`
const ImageWrapper = styled.div`
width:80%;
margin:30px auto;
height:auto;
display:flex;
flex-direction:row;
justify-content:space-around;
word-wrap:wrap;
flex-wrap: wrap;
transition:500ms;
`

const Imager = styled.div`
    width:30%;
    margin-bottom:30px;
    height:180px;
    background-color:gray;
    border-radius:20px;
    overflow:hidden;
    transition:300ms;
    @media only screen and (max-width:1000px) {
        width:100%;
    }
    &:hover{
        transition:300ms;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.75);
    }
    img{
        width:100%;
        height:100%;
        object-fit:cover;
    }
`
const Modal = styled.div`
 /*display: none;*/
  position: fixed;
  z-index: 100;
  padding: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: black;
  box-sizing:border-box;
`
const ModelContent = styled.div`
    position: relative;
  background-color: #fefefe;
  margin: auto;
  box-sizing:border-box;
  padding: 0;
  width: 90%;
  height:100%;
  /* max-width: 1200px; */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
img {
  margin-bottom: -4px;
  width:100%;
}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
`
const MySlides = styled.div`
/* display: none; */
width:100%;
height:100%;
img{
    width:100%;
    height:100%;
    object-fit:contain;
    background-color:black;
    }
`
const NumberText = styled.div`
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: -40px;
    left:50%;
`
const Row = styled.div`
 &:after {
  content: "";
  display: table;
  clear: both;
  }

`
const Column = styled.div`
    float: left;
  width: 25%;
  padding: 0 8px;
  img{
      width:100%;
  }
`
const Close = styled.span`
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
}
`
const ArtirButton = styled.div`
width:15%;
padding:10px;
background-color:#60a917;
color:white;
margin:0px auto;
border-radius:10px;
text-align:center;
box-sizing:border-box;
@media only screen and (max-width:1000px) {
    width:100%;
    }
`
export default function Gallery() {
    const [modal, setModal] = useState(false);
    const [slide, setSlide] = useState(0);
    const [gosterimSayisi, setGS] = useState(6);
    function showSlides(n) {
        setSlide(n)

    }
    function plusSlides(n) {
        let deger = slide + n
        if (deger > SlidesStore.length - 1) {
            deger = 0
        }
        if (deger < 0) {
            deger = SlidesStore.length - 1
        }
        showSlides(deger);
    }
    function openModal(n) {
        setModal(true);
        showSlides(n)
    }
    function closeModal() {
        setModal(false);
    }
    return <GaleriWrapper>
        <Title>Galeri</Title>
        <ImageWrapper>
            {SlidesStore.map((x, i) => {
                if (i < gosterimSayisi) {
                    return <Imager><img src={x.image} onClick={() => openModal(i)} /></Imager>
                } else {
                    return false
                }

            })}
        </ImageWrapper>
        <ArtirButton onClick={() => setGS(gosterimSayisi + 6)}>Daha fazla göster</ArtirButton>
        {modal ? <Modal>
            <Close onClick={closeModal}>&times;</Close>
            <ModelContent>
                {SlidesStore.map((x, i) => {
                    if (slide == i) {
                        return <MySlides>
                            <NumberText>{i + 1} / {SlidesStore.length}</NumberText>
                            <img src={x.image} />
                        </MySlides>
                    } else {
                        return false
                    }

                })}
                <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>

                <div className="caption-container">
                    <p id="caption"></p>
                </div>
            </ModelContent>
        </Modal> : false}
    </GaleriWrapper>
}
