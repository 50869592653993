import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Carousel from './carousel';
import Aksam from '../../../img/news/aksam.jpg'
import Hurriyet from '../../../img/news/hurriyet.jpg'
import Sabah from '../../../img/news/sabah.jpg'
import R1 from '../../../img/news/r1.pdf'
import K1 from '../../../img/news/karen1.png'
import R2 from '../../../img/news/r2.pdf'
import K2 from '../../../img/news/karen2.png'


const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Alan = styled.div`
flex-wrap:nowrap;
width:100%;
flex-shrink:0;
display:flex;
flex-direction:row;
box-sizing:border-box;
justify-content:center;
@media (max-width: 1000px) {
    flex-direction:row;
    flex-wrap:wrap;
  }
`
const Kapsayici = styled.div`
flex-basis:33%;
display:flex;
flex-direction:column;
// flex-shrink:0;
padding:20px;
box-sizing:border-box;
@media (max-width: 1024px) {
    flex-basis: 33%;
    // flex-shrink:1;
}
@media (max-width: 1000px) {
    flex-basis: 50%;
    // flex-shrink:1;
}
img{
    overflow:hidden;
    width:100%;
    border-radius:20px;
    // height:400px;
    object-fit:cover;
    // object-position:top;
    @media (max-width: 1000px) {
        width: 100%;
        height:200px;
    }
}
span{
    // float:left;
    margin:0px;
    display:block;
}
`
export default function CarouselWrap() {
    return (
        <Page>
            <Carousel initialHeight={1000} initialWidth={1000}>
                <Alan>
                    <Kapsayici><a href={Aksam}
                        target="_blank"
                        ><img src={Aksam} /></a><span>Aksam Gazetesi</span></Kapsayici>
                    <Kapsayici><a href={R1}
                        target="_blank"
                        ><img src={K1} /></a><span>KAREN HANIM RÖPORTAJ 01</span></Kapsayici>
                  <Kapsayici><a href={R2}
                        target="_blank"
                        ><img src={K2} /></a><span>KAREN HANIM RÖPORTAJ 02</span></Kapsayici>
                </Alan>
                {/* <Alan>
                    <Kapsayici><a href={Sabah}
                        target="_blank"
                        ><img src={Sabah} /></a><span>Sabah Gazetesi</span></Kapsayici>
                    <Kapsayici><a href={Hurriyet}
                        target="_blank"
                        ><img src={Hurriyet} /></a><span>Hürriyet Gazetesi</span></Kapsayici>
                </Alan> */}

            </Carousel>
        </Page>
    );
}