import Slide1 from '../../img/gallery/1.jpeg'
import Slide2 from '../../img/gallery/2.jpeg'
import Slide3 from '../../img/gallery/3.jpeg'
import Slide4 from '../../img/gallery/4.jpeg'
import Slide5 from '../../img/gallery/5.jpeg'
import Slide6 from '../../img/gallery/6.jpeg'
import Slide7 from '../../img/gallery/7.jpeg'
import Slide8 from '../../img/gallery/8.jpeg'
import Slide9 from '../../img/gallery/9.jpeg'
import Slide10 from '../../img/gallery/10.jpeg'
import Slide11 from '../../img/gallery/11.jpeg'
import Slide12 from '../../img/gallery/12.jpeg'
import Slide13 from '../../img/gallery/13.jpeg'
import Slide14 from '../../img/gallery/14.jpeg'
import Slide15 from '../../img/gallery/15.jpeg'
import Slide16 from '../../img/gallery/16.jpeg'
import Slide17 from '../../img/gallery/17.jpeg'
import Slide18 from '../../img/gallery/18.jpeg'
import Slide19 from '../../img/gallery/19.jpeg'
import Slide20 from '../../img/gallery/20.jpeg'
import Slide21 from '../../img/gallery/21.jpeg'
import Slide22 from '../../img/gallery/22.jpeg'
import Slide23 from '../../img/gallery/23.jpeg'
import Slide24 from '../../img/gallery/24.jpeg'
import Slide25 from '../../img/gallery/25.jpeg'
import Slide26 from '../../img/gallery/26.jpeg'
import Slide27 from '../../img/gallery/27.jpeg'
import Slide28 from '../../img/gallery/28.jpeg'
import Slide29 from '../../img/gallery/29.jpeg'
import Slide30 from '../../img/gallery/30.jpeg'
import Slide31 from '../../img/gallery/31.jpeg'
import Slide32 from '../../img/gallery/32.jpeg'
import Slide33 from '../../img/gallery/33.jpeg'

import Slide34 from '../../img/gallery/34.jpeg'
import Slide35 from '../../img/gallery/35.jpeg'
import Slide36 from '../../img/gallery/36.jpeg'
import Slide37 from '../../img/gallery/37.jpeg'
import Slide38 from '../../img/gallery/38.jpeg'
import Slide39 from '../../img/gallery/39.jpeg'
import Slide40 from '../../img/gallery/40.jpeg'
import Slide41 from '../../img/gallery/41.jpeg'
import Slide42 from '../../img/gallery/42.jpeg'
import Slide43 from '../../img/gallery/43.jpeg'
import Slide44 from '../../img/gallery/44.jpeg'
import Slide45 from '../../img/gallery/45.jpeg'
import Slide46 from '../../img/gallery/46.jpeg'
import Slide47 from '../../img/gallery/47.jpeg'
import Slide48 from '../../img/gallery/48.jpeg'
import Slide49 from '../../img/gallery/49.jpeg'
import Slide50 from '../../img/gallery/50.jpeg'
import Slide51 from '../../img/gallery/51.jpeg'
import Slide52 from '../../img/gallery/52.jpeg'
import Slide53 from '../../img/gallery/53.jpeg'
import Slide54 from '../../img/gallery/54.jpeg'
import Slide55 from '../../img/gallery/55.jpeg'
import Slide56 from '../../img/gallery/56.jpeg'
import Slide57 from '../../img/gallery/57.jpeg'
import Slide58 from '../../img/gallery/58.jpeg'
import Slide59 from '../../img/gallery/59.jpeg'

export default [
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide27,
    // },
    // {
    //     title: "Third Slide",
    //     subtitle: "last one! ✨",
    //     image: Slide7
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide9,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide26,
    // },
    // {
    //     title: "Second Slide",
    //     subtitle: "denem",
    //     image: Slide25
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide1,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide28,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide29,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide30,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide31,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide32,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide3,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide4,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide11,
    // },
    // {
    //     title: "Second Slide",
    //     subtitle: "denem",
    //     image: Slide5
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide14,
    // },
    // {
    //     title: "Third Slide",
    //     subtitle: "last one! ✨",
    //     image: Slide33
    // },
    // {
    //     title: "Third Slide",
    //     subtitle: "last one! ✨",
    //     image: Slide6
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide13,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide14,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide15,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide19,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide16,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide17,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide18,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide20,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide21,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide22,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide23,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide24,
    // },
    // {
    //     title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
    //     subtitle: 'Çünkü çocuklarımız herşeyimiz…',
    //     image: Slide33,
    // },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide34,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide35,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide36,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide37,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide38,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide39,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide40,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide41,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide42,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide43,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide44,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide45,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide46,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide47,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide48,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide49,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide50,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide51,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide52,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide53,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide54,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide55,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide56,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide57,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide58,
    },
    {
        title: '“Bizce küçük misafirleri ağırlamak ayrı bir sanat, kusursuz misafir deneyimi, kusursuz bir mini kulüp ile mümkün…“',
        subtitle: 'Çünkü çocuklarımız herşeyimiz…',
        image: Slide59,
    },
]