import AboutUs from './AboutUs';
import Home from './Home';
import Ik from './Ik';
import ContactUs from './ContactUs';
import Referanslar from './Referanslar';


export default [
    Home,
    AboutUs,
    Referanslar,
    Ik,
    ContactUs
];