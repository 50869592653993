import React from 'react';
import styled from 'styled-components'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Carousel from './carousel'

const MiniInfoWrapper = styled.section`
width:100%;
padding: 40px 120px;
box-sizing:border-box;
background-color:#FF9229;
float:left;
@media (max-width: 1000px) {
    padding: 40px 20px;
  }
h3{
    color:white;
    /* width:90%; */
    float:left;
}
`;
const IconWrapper = styled.div`
float:right;
margin-top:2em;
svg{
    color:white;
    width:30px;
    height:30px;
    float:left;
}
`
    ;
export default function MiniInfo() {
    return <MiniInfoWrapper>
        <Carousel/>
    </MiniInfoWrapper>
}