import { createGlobalStyle } from 'styled-components';

import NameOfYourFontWoff from './DESYREL_.ttf';
// import NameOfYourFontWoff2 from './nameOfYourFont.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Desyrel';
        src: url(${NameOfYourFontWoff}) format('truetype');
        font-weight: 300;
        font-style: normal;
    },
    .logoFont{
        font-family:"Desyrel";
    }
`;